import React, { useState } from "react";
import styles from "./Content.module.css";
import DateInput from "./DateInput";
import moment from "moment-timezone";
import momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment);

export default function Content() {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(25.5, "hours"));

  const duration = moment.duration(endDate.diff(startDate));
  const timeZone = moment.tz.guess();

  return (
    <div className={styles.container}>
      <div>
        <h2>Your Dates</h2>
        <div>
          <DateInput
            label={"Start"}
            value={startDate}
            onChange={(mom) => setStartDate(mom)}
            displayTimeZone={timeZone}
          />
          <DateInput
            label={"End"}
            value={endDate}
            onChange={(mom) => setEndDate(mom)}
            displayTimeZone={timeZone}
          />
        </div>
      </div>
      <div>
        <h2>Your time difference</h2>
        <div className={styles.timediff}>
          {duration.format("Y [year], D [day], H [hour and] m [min]")}
        </div>
      </div>
    </div>
  );
}
