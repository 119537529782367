import React from "react";
import styles from "./DateInput.module.css";
import Datetime from "react-datetime";

export default function DateInput({ label, ...props }) {
  return (
    <div className={styles.container}>
      <label>{label}</label>
      <Datetime {...props} closeOnSelect />
    </div>
  );
}
